import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Navbar from './components/Navbar';
import AdminCustomerDashboardPage from './pages/AdminCustomerDashboardPage';
import AdminProductDashboardPage from './pages/AdminProductDashboardPage';
import LoginPage from './pages/LoginPage';
import CustomerDashboardPage from './pages/CustomerDashboardPage';
import { useAuth } from './context/AuthContext';
import './App.css';
import AdminEdgeDevicesPage from './pages/AdminEdgeDevicesPage';
const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return null; // Or a loading spinner
  }

  // Check if user is not authenticated or token is expired
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <div className="min-h-screen bg-gray-50">
          <Navbar />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <CustomerDashboardPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/customers"
              element={
                <PrivateRoute>
                  <AdminCustomerDashboardPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/products"
              element={
                <PrivateRoute>
                  <AdminProductDashboardPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/edge-devices"
              element={
                <PrivateRoute>
                  <AdminEdgeDevicesPage />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
};

export default App;