import { useState } from 'react';
import { generateLicence } from '../services/api';
import { Copy } from 'lucide-react';

const EdgeDeviceGenerateLicence = ({ siteKey }) => {
  const [licenceRequest, setLicenceRequest] = useState('');
  const [generatedLicence, setGeneratedLicence] = useState('');
  const [loading, setLoading] = useState(false);
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const handleGenerateLicence = async () => {
    if (!licenceRequest.trim()) {
      return;
    }

    try {
      setLoading(true);
      const response = await generateLicence(siteKey, "TINT", licenceRequest);
      setGeneratedLicence(response.licence);
    } catch (error) {
      console.error('Error generating licence:', error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedLicence);
    setShowCopyNotification(true);
    setTimeout(() => setShowCopyNotification(false), 2000);
  };

  return (
    <div className="bg-gradient-to-br from-white to-gray-50 rounded-2xl shadow-lg p-8 hover:shadow-2xl transition-all duration-300 border border-gray-100 backdrop-blur-sm">
      <div>
        <label htmlFor="licenceRequest" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#233563] to-blue-500">
          Licence Request
        </label>
        <textarea
          id="licenceRequest"
          value={licenceRequest}
          onChange={(e) => setLicenceRequest(e.target.value)}
          className="mt-4 w-full px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#233563] min-h-[100px]"
          placeholder="Enter encrypted licence request..."
        />
      </div>

      <button
        onClick={handleGenerateLicence}
        disabled={loading}
        className="mt-6 w-full flex justify-center items-center py-3 px-6 rounded-xl text-white font-medium
          bg-gradient-to-r from-[#233563] to-blue-500 hover:from-[#1a284d] hover:to-blue-600
          transform hover:scale-[1.02] transition-all duration-300 shadow-lg hover:shadow-xl disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {loading ? (
          <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent"></div>
        ) : (
          'Generate Licence'
        )}
      </button>

      {generatedLicence && (
        <div className="mt-6 space-y-4">
          <label className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#233563] to-blue-500">
            Generated Licence
          </label>
          <div className="mt-4 p-4 bg-blue-50 rounded-xl relative">
            <textarea
              readOnly
              value={generatedLicence}
              className="w-full bg-transparent border-none focus:outline-none min-h-[100px] resize-none"
            />
            <div className="absolute top-2 right-2">
              {showCopyNotification && (
                <div className="absolute right-0 -top-8 bg-gray-800 text-white text-sm py-1 px-2 rounded shadow-lg">
                  Copied!
                </div>
              )}
              <button
                onClick={copyToClipboard}
                className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                title="Copy to clipboard"
              >
                <Copy size={16} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EdgeDeviceGenerateLicence;
